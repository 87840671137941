import { render, staticRenderFns } from "./batch-allot.vue?vue&type=template&id=29dfd64e&scoped=true"
import script from "./batch-allot.vue?vue&type=script&lang=ts"
export * from "./batch-allot.vue?vue&type=script&lang=ts"
import style0 from "./batch-allot.vue?vue&type=style&index=0&id=29dfd64e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29dfd64e",
  null
  
)

export default component.exports