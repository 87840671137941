var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import { Dependencies } from "~/core/decorator";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import { Prop, Emit } from "vue-property-decorator";
import { CaseInfoDistributeService } from "~/services/business-service/caseinfo-distribute.service";
var OrgAllocatePreview = /** @class */ (function (_super) {
    __extends(OrgAllocatePreview, _super);
    function OrgAllocatePreview() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = false;
        return _this;
    }
    OrgAllocatePreview.prototype.onPreStep = function () { };
    OrgAllocatePreview.prototype.close = function () { };
    OrgAllocatePreview.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.caseInfoDistributeService.distributedCasesConfirm(this.distributeConfigModel).subscribe(function (data) {
            _this.$message.success("案件分配成功!");
            _this.close();
            _this.loading = false;
        }, function (error) {
            _this.$message.error(error.msg);
            _this.loading = false;
        });
    };
    __decorate([
        Dependencies(CaseInfoDistributeService)
    ], OrgAllocatePreview.prototype, "caseInfoDistributeService", void 0);
    __decorate([
        Prop()
    ], OrgAllocatePreview.prototype, "distributeConfigModel", void 0);
    __decorate([
        Prop()
    ], OrgAllocatePreview.prototype, "telNumber", void 0);
    __decorate([
        Emit("preStep")
    ], OrgAllocatePreview.prototype, "onPreStep", null);
    __decorate([
        Emit("close")
    ], OrgAllocatePreview.prototype, "close", null);
    OrgAllocatePreview = __decorate([
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm,
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
            },
        })
    ], OrgAllocatePreview);
    return OrgAllocatePreview;
}(Vue));
export default OrgAllocatePreview;
